
import { Component, Vue, Prop, Inject } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";
import { PROVIDER, SERVICES as S } from "@/config/literals";

import { IEquipmentBrandService } from "@/services";

import { EquipmentBrandInfos } from "@/domain";

@Component
export default class EquipmentBrandAutocomplete extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  value!: string | null;

  @Prop({ required: false, default: null })
  brandIdToInclude!: string | null;

  get equipmentBrandService() {
    return this.container.resolve<IEquipmentBrandService>(
      S.EQUIPMENT_BRAND_SERVICE
    );
  }

  get filteredBrands() {
    const brands = _.filter(
      this.brands,
      (b) => !b.disabled || b.id == this.brandIdToInclude
    );

    return _.map(brands, (s) => {
      return {
        id: s.id,
        name: s.name,
      };
    });
  }

  brands: EquipmentBrandInfos[] = [];
  loading: boolean = false;

  mounted() {
    this.fetch();
  }

  async fetch() {
    try {
      this.loading = true;
      this.brands = await this.equipmentBrandService.getMany({
        ids: [],
        includeEnabled: true,
        includeDisabled: true,
      });
    } finally {
      this.loading = false;
    }
  }
}
